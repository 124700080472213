<template>
  <v-dialog
    v-bind="$attrs"
    :value="visible"
    :scrollable="true"
    :persistent="uploading || loading"
    max-width="1024"
    @input="$emit('update:visible', $event)"
    @keydown.enter="handleSelectClick"
    @keydown.esc="handleCancelClick"
  >
    <media-manager
      v-model="selectedIds"
      :page.sync="page"
      :type-filter="typeFilter"
      :multiple="multiple"
      :loading.sync="loading"
      :selected-ids="selectedIds"
      @uploading="handleUploading"
    >
      <template #actions>
        <!-- Close -->
        <v-btn
          :disabled="uploading || loading"
          text
          @click="handleCancelClick"
        >
          {{ $t('ui.cancel') }}
        </v-btn>
        <!-- Send report -->
        <v-btn
          :disabled="selectDisabled === true || uploading === true"
          color="primary"
          @click="handleSelectClick"
        >
          {{ $t('mediamanager.select') }}
        </v-btn>
      </template>
    </media-manager>
  </v-dialog>
</template>

<script>
import gql from 'graphql-tag';
import mediaManagerMixin from  '../../mixins/mediaManager';
import MediaManager from '../MediaManager/MediaManager';

export default {
  name: 'MediaSelectDialog',
  components: {
    MediaManager
  },
  mixins: [
    mediaManagerMixin
  ],
  inheritAttrs: false,
  props: {
    value: {
      type: [Number, Array],
      default: null,
    },
    visible: {
      type: Boolean,
      default: false,
    },
    info: {
      type: Object,
      default: null,
    },
    typeFilter: {
      type: Array,
      default: null,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      selectedIds: null,
      page: 1,
      uploading: false,
      loading: false,
    };
  },
  computed: {
    selectDisabled () {
      return this.loading || this.selectedIds == null;
    },
  },
  watch: {
    value: {
      immediate: true,
      /**
       * Update selected id when value changes
       */
      handler (value) {
        this.selectedIds = value;
      },
    },
    /**
     * Reset selected id and page when dialog closes
     */
    visible (visible) {
      if (visible === false) {
        this.selectedIds = this.value;
        this.page = 1;
      }
    },
  },
  methods: {
    handleUploading (value) {
      this.uploading = value;
    },
    handleCancelClick () {
      this.$emit('update:visible', false);
      this.$emit('cancel');
    },
    handleSelectClick () {
      this.$emit('update:visible', false);
      this.$emit('input', this.selectedIds);
    },
  }
};
</script>
